import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import { ButtonV2, CoreButtonTypes, useClassNames, useToastAction } from '@metaforcelabs/metaforce-core';
import React, { useContext, useEffect, useState } from 'react';
import { initiazlizeWorkflowTrigger, testWorkflowStep } from '../../../../api/workflowDefinition';
import { WorkflowContext, WorkflowStepContext, WorkflowValuesContext } from '../../../../contexts';
import { httpStatusCodes, workflowAppType, workflowStepType } from '../../../../utils/constants'
import { convertStepTestValuesToList } from '../utils';
import WorkflowEventAppIcon from '../workflowAppIcon';
import CollabsableSection from './collapsableSection'
import SetupActionStep from './setupActionStep';
import saveAs from 'file-saver';
import { FaFilePdf } from '../../../../components/FaIcon';
import { useWorkflowStep } from '../../../../hooks/useWorkflowStep';
import { InputText } from '../../../../components/Form/InputText';

export default function SetupAction({ setupStepIdx, activeStepSection, setActiveStep, workflowStep, workflowStepIdx }) {
    const { workFlow, ...workflowContext } = useContext(WorkflowContext);
    const valuesContext = useContext(WorkflowValuesContext);
    const { inUsePlaceholders, stepTestValues } = useContext(WorkflowStepContext);
    const testAction = useToastAction();
    const initializeAction = useToastAction();
    const { stepIsTrigger } = useWorkflowStep();
    const { classNames } = useClassNames();
    const [hookUrl, setHookUrl] = useState()

    const handleTestSetup = () => {
        testAction.execute(async () => {
            const testValues = convertStepTestValuesToList(stepTestValues, workflowStep.id);
            const res = await testWorkflowStep(workFlow.id, workflowStep.id, testValues);
            workflowContext.setStepTestResult(workflowStep.id, res);
            if (res.isSuccess) {
                try {

                    valuesContext.updateWorkflowStepValue(workflowStep.id, "api-response", res.testResponseData, true);
                } catch (error) {

                }

            }

        }, "Test failed")
    }

    const handleIntitializeTrigger = () => {

        if(!stepIsTrigger(workflowStep)){
            return;
        }

        const currentHook = getHookUrlValue();

        if (currentHook) {
            return;
        }
        initializeAction.executeAsync(async () => {
            const testValues = convertStepTestValuesToList(stepTestValues, workflowStep.id);
            const res = await initiazlizeWorkflowTrigger(workFlow.id, workflowStep.id, testValues);
            //debugger;
            valuesContext.updateWorkflowStepValue(workflowStep.id, "hookUrl", { value: res.hookUrl });
        }, "Setup failed");
    }

    const prettyPrintJson = (data) => {
        return JSON.stringify(data, null, 2)
    }
    const handleTestPlaceHolderValueChange = (placeholderId, value) => {
        stepTestValues[placeholderId] = value;
        const updatedstepTestValues = inUsePlaceholders.reduce((agg, placeholder, i) => {
            agg[placeholder.id] = stepTestValues[placeholder.id];
            return agg;
        }, {});
        workflowContext.updateStepTestValue(workflowStep.id, updatedstepTestValues);
    }

    const handleDownloadFile = ({ fileName, base64Content, contentType }) => {

        saveAs(`data:${contentType};base64,${base64Content}`, fileName);
    }

    const handleTextAreaHeight = (e) => {
        const value = e.target.value;
        const maxHeight = 200;
        const lineHeight = 24;
        const padding = 8;
        const border = 1;
        let numberOfLineBreaks = (value.match(/\n/g) || []).length;
        // min-height + lines x line-height + padding + border
        let calcHeight = lineHeight + numberOfLineBreaks * lineHeight + (padding * 2) + (border * 2);
        const newHeight = calcHeight > maxHeight ? maxHeight : calcHeight;
        e.target.style.height = newHeight + "px"
    }

    const getHookUrlValue = () => {

        const hookValue = valuesContext.getWorkflowStepValue(workflowStep.id, "hookUrl");
        console.log(hookValue);
        return hookValue?.value;
    }

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    useEffect(() => {
        setHookUrl(getHookUrlValue());
    }, [valuesContext])
    


    return workflowStep.selectedEvent ? (
        <CollabsableSection sectionTitle={"Setup " + (workflowStep.stepType === workflowStepType.action ? "Action" : "Trigger")}
            stepIdx={setupStepIdx}
            isActiveStep={activeStepSection === setupStepIdx}
            setActiveStep={setActiveStep}
            onOpened={()=>handleIntitializeTrigger()}>
            {
                workflowStep.selectedEvent?.setupSteps?.map(so => (
                    <div key={so.name}>
                        <SetupActionStep workflowStep={workflowStep} setupActionStep={so} stepIdx={workflowStepIdx} />
                    </div>
                ))
            }
            {
                workflowStep.selectedEvent.useTest && (
                    <>
                        <div className='mt-4 space-y-2'>
                            <div className='text-lg font-semibold'>
                                Test Setup
                            </div>
                            {
                                workflowStep.appEvent.type === workflowAppType.webhook && (
                                    <div>
                                        <div className='text-gray-800 font-semibold text mb-1'>
                                            Webhook URL:
                                        </div>

                                        <div className='flex text-gray-700 font-medium bg-gray-50 justify-between px-3 py-2 items-center border border-gray-300 rounded-lg mb-1'>
                                            {
                                                initializeAction.isExecuting ? "Creating hook..." : hookUrl
                                            }
                                            {
                                                hookUrl && <ButtonV2 label={"Copy"} type={CoreButtonTypes.cta}
                                                    disabled={!hookUrl}
                                                    onClick={() => handleCopyToClipboard(getHookUrlValue())}
                                                />
                                            }
                                        </div>
                                        <div className='text-gray-600 text-sm'>
                                            Use the webhook URL above to activate the trigger
                                        </div>
                                        {
                                            hookUrl && <div className='text-gray-700 font-medium mt-2'>
                                                We started collecting requests in your Webhooks. We'll find the most recent one to confirm your trigger is set up correctly. If no requests are found, you'll need to create one.
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            {inUsePlaceholders && inUsePlaceholders.length > 0 && (
                                <>
                                    <div className='grid grid-cols-12 space-x-4'>
                                        <div className='col-span-6 sm:col-span-5 md:col-span-4 xl:col-span-3 flex items-center justify-end font-md font-medium'>Placeholder</div>
                                        <div className='col-span-6 sm:col-span-7 md:col-span-8 xl:col-span-9 font-md  font-medium'>Test Value</div>
                                    </div>
                                    {
                                        inUsePlaceholders.map(placeholder => (
                                            <div key={placeholder.id} className='grid grid-cols-12 space-x-4'>
                                                <div className='col-span-6 sm:col-span-5 md:col-span-4 xl:col-span-3 flex items-center justify-end'>
                                                    <span
                                                        className={classNames("space-x-2 px-2 bg-white rounded border-gray-200 border shadow")}
                                                    >
                                                        <WorkflowEventAppIcon iconKey={placeholder.icon} className="h-4 w-5 inline-block shadow rounded p-0.5" />
                                                        <span className={classNames("font-semibold text-md")}> <span>{""}</span> <span>{placeholder.stepIdx + 1}. {placeholder.name}</span></span>
                                                    </span>
                                                </div>
                                                <div className="col-span-6 sm:col-span-7 md:col-span-8 xl:col-span-9">
                                                    <textarea
                                                        style={{ resize: 'none' }}
                                                        placeholder='Valid test value...'
                                                        name={placeholder.id}
                                                        rows={1}
                                                        onKeyUp={e => handleTextAreaHeight(e)}
                                                        value={stepTestValues[placeholder.id] || ''}
                                                        onChange={e => handleTestPlaceHolderValueChange(placeholder.id, e.target.value)}
                                                        className="min-w-0 w-full border-transparent rounded-lg bg-gray-100 hover:shadow-sm focus:border-indigo-500 focus:border-sm cursor-text px-4 py-2" />
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className="mt-2 text-gray-500 text-sm">
                                        Enter test values that will yield a valid test results. This is needed in order to use response values in further steps
                                    </div></>)}
                        </div>
                        <div className='mt-4 flex justify-center'>
                            <button
                                type="button"
                                disabled={testAction.isExecuting || workflowContext.saving}
                                className={"flex justify-center items-center space-x-2 py-2 px-4 w-5/12 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-400 hover:bg-red-300 focus:outline-none"}
                                onClick={e => { handleTestSetup() }}
                            >
                                {
                                    testAction.isExecuting && (
                                        <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    )
                                }
                                <span>
                                    {
                                        stepIsTrigger(workflowStep) ? "Test Trigger" : "Test Action"
                                    }
                                </span>
                            </button>
                        </div>
                    </>
                )
            }
            {
                workflowStep.selectedEvent.useTest && !testAction.isExecuting && workflowStep.testResult && (
                    <div className='mt-4 space-y-2'>
                        <div className='text-lg font-semibold'>
                            Test Result
                        </div>
                        <div className='flex justify-center'>
                            <span className={
                                classNames('inline-flex items-center py-2 px-6 text-lg font-semibold rounded-lg text-white',
                                    workflowStep.testResult?.isSuccess ? "bg-green-500" : "bg-red-500"
                                )
                            }>
                                {
                                    workflowStep.testResult?.isSuccess ? (<>
                                        <CheckCircleIcon className='h-5 w-5 mr-2' />Test Successfull
                                    </>) : (
                                        <><XCircleIcon className='h-5 w-5 mr-2' />Test Failed</>
                                    )
                                }

                            </span>
                        </div>
                        <div>
                            <div className='p-4 bg-gray-50 rounded-lg space-y-2'>
                                <div>
                                    <span className="font-semibold mr-2">Is Success: </span>
                                    {workflowStep.testResult?.isSuccess ? "Success" : "Failed"}
                                </div>
                                <div>
                                    <span className="font-semibold mr-2">HTTP Status Code: </span>
                                    {workflowStep.testResult?.httpStatusCode} {workflowStep.testResult?.httpStatusCode ? `(${httpStatusCodes["CODE_" + workflowStep.testResult?.httpStatusCode]})` : ""}
                                </div>
                                {
                                    workflowStep.testResult?.isSuccess ? (
                                        <div>
                                            <span className="font-semibold mr-2">Response Data: </span>
                                            <div className='ml-2'>
                                                <pre className='whitespace-pre-wrap'>{prettyPrintJson(workflowStep.testResult?.testResponseData || workflowStep.testResult?.testResponseDataArray)}</pre>
                                            </div>
                                            {workflowStep.testResult?.testResponseFile &&
                                                <div className='mt-2'>
                                                    <span className="font-semibold mr-2">File Response: </span>
                                                    <button
                                                        type="button"
                                                        className={"flex justify-center items-center space-x-2 p-2 border border-transparent rounded-md border border-gray-300 shadow-sm text-sm font-medium bg-white hover:bg-gray-50 focus:outline-none"}
                                                        onClick={e => { handleDownloadFile(workflowStep.testResult?.testResponseFile) }}
                                                    >
                                                        <FaFilePdf size={10} className={""} />
                                                        <span>
                                                            Download
                                                        </span>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    ) : (
                                        <div>
                                            <span className="font-semibold mr-2">Error Message: </span>
                                            {workflowStep.testResult?.errorMessage}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>)
            }

        </CollabsableSection>
    ) : (<></>)
}
