import { TrashIcon } from "@heroicons/react/outline";
import { Button } from "../../../../components/Form/Button";
import Modal from "../../../../components/Modals/modal";
import { useContext, useState } from "react";
import { useClassNames } from "@metaforcelabs/metaforce-core";
import { FolderContext, FolderSidebarContext } from "../../../../contexts";

export default function SidebarItem({ item, index }) {
    const { selectedFolderId } = useContext(FolderContext);
    const { onSelectFolderClick, onDeleteFolder } = useContext(FolderSidebarContext);
    const current = selectedFolderId === item.id;
    const [showModal, setShowModal] = useState(false);
    const { classNames } = useClassNames();

    const handleClick = (e) => {
        current
            ? e.preventDefault()
            : item.onClick
                ? item.onClick(e, item, index)
                : onSelectFolderClick(e, item, index)
    }

    return (
        <>
            <div
                key={item.name}
                className={`flex px-3 py-2 text-sm group font-medium cursor-pointer items-center rounded-md 
                    ${item?.isAddItem ? "bg-white sticky top-0 justify-between flex-row-reverse py-4" : current ? "bg-indigo-100 text-gray-900" : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"}`}
                aria-current={current ? 'page' : undefined}
                onClick={handleClick}
            >
                <item.icon
                    className={
                        `flex-shrink-0 -ml-1 mr-3 ${item?.isAddItem ? "h-4 w-4" : current ? "text-gray-500 h-6 w-6" : "text-gray-400 group-hover:text-gray-500 h-6 w-6"}`
                    }
                    aria-hidden="true"
                />
                <span className={`truncate whitespace-nowrap overflow-hidden overflow-ellipsis select-none ${item?.isAddItem ? "text-base" : ""}`}>
                    {item.name}
                </span>
                {
                    current && !item.isStatic &&
                    <TrashIcon
                        onClick={() => setShowModal(true)}
                        className={classNames(
                            'h-5 w-5 cursor-pointer',
                            !item.isAddItem ? "ml-auto mr-2" : ""
                        )}
                    />
                }
            </div>
            <Modal
                size={"large"}
                title={"Are you sure you want to delete the folder?"}
                isOpen={showModal}
                onClose={() => setShowModal(false)}
            >
                <div className='flex space-x-4 justify-end'>
                    <Button onClick={() => setShowModal(false)} text={"Cancel"} theme='white' />
                    <Button onClick={(event) => onDeleteFolder(item.id)} text={"Delete"} />
                </div>
            </Modal>
        </>
    )
}