import { CheckIcon, FolderAddIcon, XIcon } from "@heroicons/react/outline";
import { FolderSidebarContext } from "../../../../contexts";
import { useContext, useState } from "react";
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { createWorkflowDefinitionFolder } from "../../../../api/workflowDefinitionFolder";
import toast from "react-hot-toast";

export default function AddFolderField({ field, isPrivate }) {
    const { inCreationFolder, setInCreationFolder, initialInCreationFolderState, allFolders, onFolderCreated } = useContext(FolderSidebarContext);
    const show = inCreationFolder[field];
    const [newFolder, setNewFolder] = useState({ name: "", isPrivate: false })
    const [isInvalid, setIsInvalid] = useState(false);
    const createAction = useToastAction();

    const handleAddFolderFieldChange = (e, isPrivate) => {
        const { value } = e.target;
        setNewFolder({ ...newFolder, name: value, isPrivate });
    }

    const handleCancelFolderCreation = () => {
        setInCreationFolder(initialInCreationFolderState)
        setNewFolder({ name: "", isPrivate: false })
    }

    const handleCreateFolder = async () => {
        if (allFolders.privateFolders.some(m => m.name.toLowerCase() === newFolder.name.toLowerCase()) ||
            allFolders.sharedFolders.some(m => m.name.toLowerCase() === newFolder.name.toLowerCase())) {
            toast.error('Folder name is same');
            setIsInvalid(true);
        }
        else {
            setIsInvalid(false);
            createAction.execute(async () => {
                await createWorkflowDefinitionFolder(newFolder);
                onFolderCreated()
                setNewFolder({ ...newFolder, name: "" })
            }, "Failed to create folder")
        }
    }

    return show && (
        <>
            <div
                className={`flex px-3 py-2 text-sm group font-medium items-center rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900 justify-between`}
            >
                <FolderAddIcon className={`flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500`} />
                <input className={`focus:outline-none p-1 border rounded-md ${isInvalid ? "border-red-500 focus:border-red-500" : "border-gray-300 focus:ring-gray-400 focus:border-gray-400"}`}
                    key={field}
                    name={field}
                    value={newFolder.name}
                    onChange={(e) => handleAddFolderFieldChange(e, isPrivate)}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            handleCreateFolder();
                        }
                    }}
                />
                <CheckIcon className='z-30 cursor-pointer h-5 w-5' onClick={() => handleCreateFolder()} />
                <XIcon className=' h-5 w-5 cursor-pointer' onClick={() => handleCancelFolderCreation()} />
            </div>
        </>
    )
}