import { get, post, destroy, BaseUrl, put } from './api';

const controller = "WorkflowDefinitionFolder";

export const getWorkflowDefinitionFolders = async () => {
    return await get(controller, null, BaseUrl.WorkFlow);
}

export const createWorkflowDefinitionFolder = async (workflowDefinitionFolder) => {
    return await post(controller, workflowDefinitionFolder, null, BaseUrl.WorkFlow);
}

export const destroyWorkflowDefinitionFolder = async (id) => {
    return await destroy(`${controller}/${id}`, null, BaseUrl.WorkFlow);
}