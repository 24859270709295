import React, { useContext, useEffect, useState } from "react";
import './styles.scss';
import { FolderIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { systemFolderIds } from "../../../utils/folders";
import SidebarItem from "./Components/sidebarItem";
import { FolderContext, FolderSidebarContext } from '../../../contexts';
import AddFolderField from "./Components/addFolderField";
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { destroyWorkflowDefinitionFolder } from "../../../api/workflowDefinitionFolder";


export default function FoldersSidebar({ folders, onFolderCreated, onFolderDeleted }) {
    const { setSelectedFolderId } = useContext(FolderContext);

    const initialInCreationFolderState = {
        privateFolder: false,
        sharedFolder: false
    }

    const [inCreationFolder, setInCreationFolder] = useState(initialInCreationFolderState)

    const onAddFolderClick = (type) => {
        setInCreationFolder((latest) => setInCreationFolder({ ...latest, [type]: !latest[type] }));
    }

    const initialFolders = {
        privateFolders: [
            { id: "privateFolder", name: "Private Folders", onClick: () => onAddFolderClick("privateFolder"), icon: PlusIcon, isAddItem: true, isStatic: true },
            { id: systemFolderIds.home, name: 'Home', icon: FolderIcon, isStatic: true }
        ],
        sharedFolders: [
            { id: "sharedFolder", name: "Shared Folders", onClick: () => onAddFolderClick("sharedFolder"), icon: PlusIcon, isAddItem: true, isStatic: true },
        ],
    }

    const [allFolders, setAllFolders] = useState(initialFolders);
    const deleteAction = useToastAction();

    useEffect(() => {
        if (folders) {
            const newFolders = { ...initialFolders }

            folders.forEach(folder => {
                const buildFolderItem = { ...folder, icon: FolderIcon, isAddItem: false, isStatic: false }
                folder.isPrivate ? newFolders.privateFolders.push(buildFolderItem) : newFolders.sharedFolders.push(buildFolderItem)
            });

            setAllFolders(newFolders);
        }
    }, [folders])

    const Divider = () => {
        return (
            <div className="relative py-0.5">
                <div className="absolute inset-0 flex items-center " aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                </div>
            </div>
        )
    }

    const onSelectFolderClick = (e, item, index) => {
        setSelectedFolderId(item.id);
    }

    const onDeleteFolder = async (id) => {
        deleteAction.execute(async () => {
            await destroyWorkflowDefinitionFolder(id);
            onFolderDeleted()
        }, "Failed to delete folder");
    }

    return (
        <>
            <FolderSidebarContext.Provider
                value={{
                    inCreationFolder: inCreationFolder,
                    setInCreationFolder: setInCreationFolder,
                    initialInCreationFolderState: initialInCreationFolderState,
                    allFolders: allFolders,
                    onFolderCreated: onFolderCreated,
                    onSelectFolderClick: onSelectFolderClick,
                    onDeleteFolder: onDeleteFolder,
                }}>
                <nav className=" space-y-1 z-10 w-1/5 h-fit" aria-label="FoldersSidebar">
                    <div className='top-0 shadow-sm border border-gray-200 sm:rounded-lg bg-white '>
                        <div className='private-shared-folders-container overflow-y-auto'>
                            {
                                allFolders.privateFolders.map((privateItem, index) =>
                                    <SidebarItem key={privateItem.id} item={privateItem} index={index} />
                                )
                            }
                            <AddFolderField field={"privateFolder"} isPrivate />
                            <Divider />
                            {
                                allFolders.sharedFolders.map((sharedItem, index) =>
                                    <SidebarItem key={sharedItem.id} item={sharedItem} index={index} />
                                )
                            }
                        </div>
                        <AddFolderField field="sharedFolder" />
                        <Divider />
                    </div>
                </nav>
            </FolderSidebarContext.Provider>
        </>
    )
}