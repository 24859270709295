import toast from "react-hot-toast";

export const useSmartFormHelper = () => {

    return {
        notFoundHandler: (smartFormName, workflowStepValues) => {
            return () => {
                toast.error(`SmartForm '${smartFormName}' is not found.`);
                if (workflowStepValues?.hasOwnProperty("smartformId")) {
                    workflowStepValues["smartformId"].valid = false;
                }
            };
        }
    }
}